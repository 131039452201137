import dayjs from 'dayjs'
import Immutable from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'

import { type availableIcons, Icon } from 'components/common/svg-icon'
import { Content } from 'components/styled/blocs'
import { Title } from 'components/styled/text'

import { DateRangeFilter } from '../filters/date-range-filter'
import { FilterBar } from '../filters/filter-bar'
import { StatsContainer } from '../orchestration-analytics/orchestration-analytics.styles'
import {
  PlatformFilter,
  type PlatformFilterOption,
} from '../orchestration-analytics/platform-filter'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import {
  OrchestrationAnalyticsFactory,
  OrchestrationAnalyticsDateRangeFactory,
  EmailAnalyticsFactory,
  SmsAnalyticsFactory,
  PushAnalyticsFactory,
  type DiscoveryFilterRecord,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { currentProjectPlatformsSelector } from 'com.batch.redux/app'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { labelsSelector } from 'com.batch/labels/store/labels.selector'

import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { useUpdateFiltersUrl } from 'com.batch/orchestration-analytics/ui/hooks/use-update-filters-url'
import { fetchGlobalDynamicStats } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats'
import {
  type ConfigName,
  getStatsConfig,
  type PlatformFilterDimensionValue,
} from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'
import { ChannelSectionTitle } from 'com.batch/shared/ui/component/channel-section-title.styles'
import { LanguageFilter } from '../filters/language-filter'
import { MailboxProviderFilter } from '../filters/mailbox-provider-filter'
import { LabelFilter } from '../filters/label-filter'
import { Grid } from 'components/common/grid'
import { CrossOrchestrationsKeyMetrics } from './cross-orchestrations-key-metrics'
import { PeriodicKeyMetrics } from '../key-metrics/periodic-key-metrics'

export const createFactory = (channel: ChannelUntilCleanup) => {
  switch (channel) {
    case 'email':
      return EmailAnalyticsFactory()
    case 'sms':
      return SmsAnalyticsFactory()
    default:
      return PushAnalyticsFactory()
  }
}

export const channelConfig: {
  [key in ChannelUntilCleanup]: {
    heading: {
      title: string
      icon: availableIcons
    }
    config: ConfigName
    loadingState: 'emailLoadingState' | 'smsLoadingState' | 'pushLoadingState'
  }
} = {
  email: {
    heading: {
      title: 'Email',
      icon: 'mail',
    },
    config: 'orchestrationStatsForEmail',
    loadingState: 'emailLoadingState',
  },
  sms: {
    heading: {
      title: 'SMS',
      icon: 'sms',
    },
    config: 'orchestrationStatsForSms',
    loadingState: 'smsLoadingState',
  },
  push: {
    heading: {
      title: 'Push',
      icon: 'push',
    },
    config: 'orchestrationStatsForPush',
    loadingState: 'pushLoadingState',
  },
}

type CrossOrchestrationAnalyticsProps = {
  channel: ChannelUntilCleanup
}
export const CrossOrchestrationsAnalytics = React.memo(
  ({ channel }: CrossOrchestrationAnalyticsProps): React.ReactNode => {
    const dispatch = useDispatch()
    const { projectKey } = useSelector(currentProjectSelector)
    const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
    const projectLabels = useSelector(labelsSelector)
    const [stats, discovery, filterDiscoveryState] = React.useMemo(() => {
      const analytics = analyticsMap.get(projectKey, OrchestrationAnalyticsFactory())
      return [
        analytics.get('channels').get(channel, createFactory(channel)).stats,
        analytics.get('discovery'),
        analytics.get('filterDiscoveryState'),
      ]
    }, [channel, analyticsMap, projectKey])
    const { dateRange, filters } = useGetAnalyticsFilters(projectKey)

    const platforms = useSelector(currentProjectPlatformsSelector)
    const [selectedPlatformOption, setSelectedPlatformOption] =
      React.useState<PlatformFilterOption>(() => {
        if (platforms.has('ios') && platforms.has('android')) {
          return 'mobile'
        }
        return platforms.first()
      })
    const platformFilter = React.useMemo(() => {
      let platformFilterList = Immutable.List<PlatformFilterDimensionValue>()
      switch (selectedPlatformOption) {
        case 'mobile':
          platformFilterList = platformFilterList.push('IOS', 'ANDROID')
          break
        case 'ios':
          platformFilterList = platformFilterList.push('IOS')
          break
        case 'android':
          platformFilterList = platformFilterList.push('ANDROID')
          break
        case 'webpush':
          platformFilterList = platformFilterList.push('WEBPUSH')
          break
      }
      return platformFilterList
    }, [selectedPlatformOption])

    const [payloadFilters, payloadDateRange] = React.useMemo(() => {
      let payloadFilters = filters
      if (channel === 'push') {
        payloadFilters = payloadFilters.set('platform', platformFilter)
      }
      return [
        payloadFilters.set('channel', Immutable.List([channel])),
        dateRange ??
          OrchestrationAnalyticsDateRangeFactory({
            from: dayjs.utc().subtract(364, 'day').startOf('day'),
            to: dayjs.utc().endOf('day'),
          }),
      ]
    }, [channel, filters, platformFilter, dateRange])

    React.useEffect(() => {
      dispatch(
        fetchGlobalDynamicStats({
          dateRange: payloadDateRange,
          config: getStatsConfig({ name: channelConfig[channel].config }),
          filters: payloadFilters,
          includePreviousPeriod: true,
        })
      ).catch(() => {})
    }, [dispatch, channel, payloadDateRange, payloadFilters])

    useUpdateFiltersUrl({ filters, dateRange, context: 'project' })

    const discoveryLanguages = React.useMemo(
      () =>
        discovery
          .get('content_language', Immutable.List<DiscoveryFilterRecord>())
          .map(({ name }) => name),
      [discovery]
    )
    const discoveryMailboxProviders = React.useMemo(
      () => discovery.get('mailbox_provider', Immutable.List<DiscoveryFilterRecord>()),
      [discovery]
    )
    const discoveryLabels = React.useMemo(() => {
      const uniqList = discovery
        .get('label', Immutable.List<DiscoveryFilterRecord>())
        .filter(({ name }) => name)
        .reduce((acc, { name }) => acc.push(...name.split(',')), Immutable.List<string>())
        .toSet()
        .toList()
      return projectLabels.labels.filter(label => uniqList.includes(label.code))
    }, [discovery, projectLabels])

    const isClearFiltersDisabled = React.useMemo(
      () => !dateRange && filters.isEmpty(),
      [dateRange, filters]
    )

    const filterCount = React.useMemo(() => {
      let count = 0
      if (discoveryLabels.size > 1) count++
      if (discoveryLanguages.size > 1) count++
      if (channel === 'email' && discoveryMailboxProviders.size > 1) count++
      return count
    }, [channel, discoveryLabels.size, discoveryLanguages.size, discoveryMailboxProviders.size])

    const filterBarTemplate = React.useMemo(
      () =>
        channel === 'push'
          ? `repeat(${filterCount}, minmax(200px, 300px)) 182px`
          : `repeat(${filterCount}, minmax(200px, 300px))`,
      [channel, filterCount]
    )

    return (
      <React.Fragment>
        <FilterBar
          token={projectKey}
          filterCount={filterCount}
          isClearButtonDisabled={isClearFiltersDisabled}
        >
          <DateRangeFilter
            token={projectKey}
            maxDaysBack={364}
            placeholder="Last 365 days"
            style={{ maxWidth: 650, width: '100%' }}
          />
          <Grid template={filterBarTemplate} gap={5} style={{ flexGrow: 1, width: '100%' }}>
            {filterDiscoveryState === 'LOADED' && (
              <React.Fragment>
                {discoveryLanguages.size > 1 && (
                  <LanguageFilter
                    token={projectKey}
                    languages={discoveryLanguages}
                    context="project"
                  />
                )}
                {discoveryLabels.size > 1 && (
                  <LabelFilter token={projectKey} labels={discoveryLabels} />
                )}
                {channel === 'email' && discoveryMailboxProviders.size > 1 && (
                  <MailboxProviderFilter
                    token={projectKey}
                    mailboxProviders={discoveryMailboxProviders}
                  />
                )}
                {channel === 'push' && (
                  <PlatformFilter
                    selectedPlatformOption={selectedPlatformOption}
                    setSelectedPlatformOption={setSelectedPlatformOption}
                    platforms={platforms}
                    context="project"
                  />
                )}
              </React.Fragment>
            )}
          </Grid>
        </FilterBar>
        <Content
          style={{
            overflow: 'visible',
            padding: '40px 26px 0',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <StatsContainer>
            <ChannelSectionTitle style={{ margin: '0 0 24px' }}>
              <Title>
                <Icon icon={channelConfig[channel].heading.icon} size={22} />
                {channelConfig[channel].heading.title}
              </Title>
            </ChannelSectionTitle>
            <CrossOrchestrationsKeyMetrics
              channel={channel}
              stats={stats}
              projectKey={projectKey}
            />
            <PeriodicKeyMetrics
              channel={channel}
              projectKey={projectKey}
              filters={payloadFilters}
              dateRange={payloadDateRange}
            />
          </StatsContainer>
        </Content>
      </React.Fragment>
    )
  }
)

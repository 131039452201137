import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { labelsSelector } from 'com.batch/labels/store/labels.selector'
import { useGetAnalyticsFilters } from '../../hooks/use-get-analytics-filters'
import { type LabelRecord } from 'com.batch/labels/models/labels.records'
import { updateAnalyticsFilters } from 'com.batch/orchestration-analytics/usecases/analytics-filters'
import { FilterSelectMulti } from 'components/filter/filter-select-multi/filter-select-multi'

type LabelFilterProps = {
  token?: string
  labels: List<LabelRecord>
}
export const LabelFilter = ({ token, labels }: LabelFilterProps): React.ReactNode => {
  const dispatch = useDispatch()
  const { filters } = useGetAnalyticsFilters(token)
  const projectLabels = useSelector(labelsSelector)
  const selectedLabels = React.useMemo(
    () =>
      projectLabels.labels.filter(label =>
        filters.get('label', Immutable.List<string>()).includes(label.code)
      ),
    [filters, projectLabels]
  )
  const sortedLabels = React.useMemo(() => {
    return labels.sortBy(label => label.description)
  }, [labels])

  const onLabelChange = React.useCallback(
    (list: List<LabelRecord>) => {
      if (token)
        dispatch(
          updateAnalyticsFilters({
            token,
            filters:
              list.size > 0
                ? filters.set(
                    'label',
                    list.map(label => label.code)
                  )
                : filters.delete('label'),
          })
        )
    },
    [dispatch, token, filters]
  )

  const optionToString = React.useCallback(
    (label?: LabelRecord | null) => label?.description ?? '',
    []
  )
  const formatTerm = React.useCallback(
    (count: number) => {
      if (count === 1) {
        return selectedLabels.first()?.description ?? `${count} label`
      } else if (count === 2) {
        return `${selectedLabels.first()?.description}, ${selectedLabels.last()?.description}`
      } else {
        return `${count} labels`
      }
    },
    [selectedLabels]
  )

  return (
    <FilterSelectMulti
      id="analytics-filter-label"
      options={sortedLabels}
      value={selectedLabels}
      onChange={onLabelChange}
      optionToString={optionToString}
      placeholder="All labels"
      term={formatTerm}
      toggleButtonWidth="100%"
      menuStyle={{ maxWidth: 300 }}
    />
  )
}

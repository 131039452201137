import dayjs from 'dayjs'
import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { DateRangePicker } from 'components/form'

import { type DateRange } from 'com.batch.common/dayjs.custom'

import { OrchestrationAnalyticsDateRangeFactory } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import { updateAnalyticsDateRange } from 'com.batch/orchestration-analytics/usecases/analytics-filters'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { DateRangeFilterContainer } from './date-range-filter.styles'
import { computeFromTodayLabel } from 'com.batch/orchestration-analytics/ui/helpers/date-label-utils'

const ranges = [
  {
    name: 'Last 7 days',
    from: dayjs.utc().subtract(6, 'day').startOf('day'),
    to: dayjs.utc().endOf('day'),
  },
  {
    name: 'Last 30 days',
    from: dayjs.utc().subtract(29, 'day').startOf('day'),
    to: dayjs.utc().endOf('day'),
  },
  {
    name: 'Last 90 days',
    from: dayjs.utc().subtract(89, 'day').startOf('day'),
    to: dayjs.utc().endOf('day'),
  },
  {
    name: 'Last 365 days',
    from: dayjs.utc().subtract(364, 'day').startOf('day'),
    to: dayjs.utc().endOf('day'),
  },
]
const rangeList = Immutable.List(ranges)

const areRangesEqual = (first?: DateRange | null, sec?: DateRange | null) =>
  first?.from.format('YYYYMMDD') === sec?.from.format('YYYYMMDD') &&
  first?.to.format('YYYYMMDD') === sec?.to.format('YYYYMMDD')

type DateFilterProps = {
  token?: string
  maxDaysBack?: number
  placeholder?: string
  style?: {
    [key: string]: string | number
  }
}
export const DateRangeFilter = ({
  token,
  maxDaysBack,
  placeholder = 'Since launch',
  style,
}: DateFilterProps): React.ReactElement => {
  const dispatch = useDispatch()
  const { dateRange } = useGetAnalyticsFilters(token)

  const setRange = React.useCallback(
    range => {
      if (!areRangesEqual(dateRange, range) && token) {
        const newRange = range
          ? OrchestrationAnalyticsDateRangeFactory({
              from: range.from,
              to: range.to,
            })
          : null
        dispatch(updateAnalyticsDateRange({ token, dateRange: newRange }))
      }
    },
    [dispatch, token, dateRange]
  )

  const isDateInvalid = React.useCallback(
    (day: Date) => {
      const isAfterToday = dayjs.utc().hour(23).minute(59).add(1, 'hour').isBefore(day)
      return maxDaysBack
        ? dayjs.utc().subtract(maxDaysBack, 'day').isAfter(day) || isAfterToday
        : isAfterToday
    },
    [maxDaysBack]
  )

  return (
    <DateRangeFilterContainer style={style}>
      <DateRangePicker
        data-track="analytics-filter-date"
        range={dateRange}
        setRange={setRange}
        onClose={setRange}
        shortcuts={rangeList}
        icon="calendar"
        disabledDays={isDateInvalid}
        placeholder={placeholder}
        valuePlaceholder={
          dateRange?.to.isSame(dayjs().endOf('day'), 'day')
            ? computeFromTodayLabel(dateRange.from)
            : undefined
        }
        changePlaceholderOnFocus
        style={{ width: '100%' }}
        singleDayRange
      />
    </DateRangeFilterContainer>
  )
}
